import React, {FC, useEffect, useRef, useState} from "react";
import {ILifeSituationServices} from "../../../models/ILifeSituation";
import Buttons from "../../Buttons/Buttons";
import icons from "../../../assets/icons/icons";
import "../styles.scss";
import LifeSituationProcessItem from "../LifeSituationProcessItem/LifeSituationProcessItem";
import Modal from "../../Modal/Modal";
import {DataPressActionCreators} from "../../../store/reducers/dataPressItem/action-creator";
import {fieldToArray} from "../../UI/functions/functions";
import FormInput from "../../FormInput/FormInput";
import {useTypeSelector} from "../../../hooks/useTypedSelector";
import LifesituationsApiRequest from "../../../api/Lifesituation/Lifesituation";
import {useDispatch} from "react-redux";
import ServicesApiRequest from "../../../api/Services/Services";
import {LifeSituationActionCreators} from "../../../store/reducers/lfieSituation/action-creatorlife";
import ErrorMessage from "../../UI/ErrorMassage/ErrorMassage";
import {Toast} from "primereact/toast";
import {FileUpload} from "primereact/fileupload";
import apiConfig from "../../../api/apiConfig";
import Checkbox from "../../Checkbox/Checkbox";

interface LifeSituationServiceItemProps {
    services: ILifeSituationServices | undefined;
    servicesOption: any;
    lifeSitaitonsId: string | undefined;
    lifeSituationActive?: any;
    readonly?: boolean;
}

const LifeSituationServiceItem: FC<LifeSituationServiceItemProps> = ({
                                                                         services,
                                                                         servicesOption,
                                                                         lifeSitaitonsId,
                                                                         lifeSituationActive,
                                                                         readonly = false,
                                                                     }) => {
    const lifeSituationServicesApi = new ServicesApiRequest();
    const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
    const [addFileModal, setAddFileModal] = useState<boolean>(false);
    const [bestPractices, setBestPractices] = useState({});
    const {isUpdate, error} = useTypeSelector(
        (state) => state.lifeSituationReducer
    );
    const [currentLifeSituationId, setCurrentLifeSituationId] = useState<string | null>(null);
    const lifeSituationApi = new LifesituationsApiRequest();
    const {dataPress} = useTypeSelector((state) => state.dataPressReducer);
    const dispatch = useDispatch();
    const toast = useRef(null);
    const storedUser = localStorage.getItem("user");
    const user = storedUser ? JSON.parse(storedUser) : null;

    const [servicesOptionCreate, setServicesOptionCreate] = useState<any>();
    console.log('servicesOption', servicesOption)
    const [updateData, setUpdateData] = useState<boolean>(false);

    const handleChangeInput = (field: string, value: string | boolean) => {
        dispatch(DataPressActionCreators.setDataPress(field, value));
    };

    const addLifeSituation = (type?: string, id?: string) => {
        type === "update"
            ? lifeSituationServicesApi.getById({id: id + "/"}).then((resp) => {
                if (resp.success) {
                    lifeSituationServicesApi.options().then((resp) => {
                        if (resp.success) {
                            //@ts-ignore
                            setServicesOptionCreate(resp?.data?.actions?.create);
                        }
                    });
                    setUpdateData(true);
                    //@ts-ignore
                    const dataServices = fieldToArray(resp.data ? resp.data : "");
                    setIsVisibleModal(true);
                    dataServices.map((item) => {
                        dispatch(
                            DataPressActionCreators.setDataPress(item.key, item.value)
                        );
                    });
                }
            })
            : lifeSituationServicesApi
                .generateIdentifier(`?life_situation_id=${lifeSitaitonsId}`)
                .then((ident) => {
                    if (ident.success) {
                        dispatch(
                            DataPressActionCreators.setDataPress(
                                "lifesituation",
                                lifeSitaitonsId ? lifeSitaitonsId : ""
                            )
                        );
                        lifeSituationServicesApi.options().then((resp) => {
                            if (resp.success) {
                                //@ts-ignore
                                setServicesOptionCreate(resp?.data?.actions?.create);
                                setIsVisibleModal(true);
                            }
                        });

                        dispatch(
                            DataPressActionCreators.setDataPress(
                                "identifier",
                                //@ts-ignore
                                ident?.data?.identifier || ""
                            )
                        );
                    }
                });
    };

    const addLifeSituationServices = (type?: string) => {
        type === "update"
            ? lifeSituationServicesApi
                .update({
                    urlParams: dataPress?.id ? dataPress?.id + "/" : "",
                    body: dataPress,
                })
                .then((resp) => {
                    if (resp.success) {
                        setIsVisibleModal(false);
                        dispatch(DataPressActionCreators.clearDataPress());
                        dispatch(LifeSituationActionCreators.setUpdate(!isUpdate));
                    } else {
                        dispatch(
                            LifeSituationActionCreators.setErr({
                                type: "error",
                                message: "Произошла ошибка при обработке данных",
                            })
                        );
                    }
                    setUpdateData(false);
                })
            : lifeSituationServicesApi.create({body: dataPress}).then((resp) => {
                if (resp.success) {
                    setIsVisibleModal(false);
                    dispatch(DataPressActionCreators.clearDataPress());
                    dispatch(LifeSituationActionCreators.setUpdate(!isUpdate));
                } else {
                    dispatch(
                        LifeSituationActionCreators.setErr({
                            type: "error",
                            message: "Произошла ошибка при обработке данных",
                        })
                    );
                }
            });
    };

    const addFileLifeSitauation = () => {
        setAddFileModal(true);
    };

    const uploadFilesAllServices = (event: any) => {
        //@ts-ignore
        toast.current.show({
            severity: "success",
            summary: "Удачно",
            detail: "Файл загружен",
            life: 3000,
        });

        const response = event.xhr.response;
        dispatch(LifeSituationActionCreators.setUpdate(!isUpdate));
    };

    const [selectedCards, setSelectedCards] = useState<string[]>([]);
    const [selectedCard, setSelectedCard] = useState<string>("");

    const toggleCardSelection = (id: string) => {
        if (selectedCards.includes(id)) {
            setSelectedCards(selectedCards.filter((cardId) => cardId !== id));
        } else {
            setSelectedCards([...selectedCards, id]);
        }
        dispatch(DataPressActionCreators.setDataPress("service", id ? id : ""));
    };

    const closeModal = (type?: string) => {
        setIsVisibleModal(false);

        dispatch(DataPressActionCreators.clearDataPress());
    };

    const servOptionData = fieldToArray(servicesOptionCreate);

    const handleCheckboxChange = (serviceId: string) => {
        lifeSituationServicesApi.setBestPractice(`${serviceId}/`).then((resp) => {
            if (resp.success) {
                dispatch(LifeSituationActionCreators.setUpdate(!isUpdate));
                setBestPractices((prev) => ({
                    ...prev,
                    //@ts-ignore
                    [serviceId]: !prev[serviceId],
                }));
            }
        });
    };

    return (
        <>
            <Toast ref={toast} className="toast"></Toast>
            {error.message && (
                <ErrorMessage
                    type={error?.type}
                    message={error?.message}
                    onClick={() => {
                    }}
                    onClose={() => {
                        dispatch(
                            LifeSituationActionCreators.setErr({type: "", message: ""})
                        );
                    }}
                />
            )}
            {isVisibleModal && (
                <Modal
                    content={
                        <div className="modalContainerGrid">
                            <h1>Добавить услугу/функцию/сервис</h1>
                            {servOptionData != null &&
                                servOptionData.map((item: any) => {
                                    if (
                                        item.key === "id" ||
                                        item.key === "processes" ||
                                        item.key === "service_type_selected" ||
                                        item.key === "lifesituation"
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <div key={item.key}>
                                            <FormInput
                                                key={item.key}
                                                style={""}
                                                value={
                                                    //@ts-ignore
                                                    dataPress && dataPress[item.key]
                                                }
                                                options={item.value.choices}
                                                disabled={item.key === "identifier" ? true : undefined}
                                                onChange={(e) => {
                                                    handleChangeInput(item.key, e);
                                                }}
                                                subInput={item.value.label}
                                                error={""}
                                                keyData={item.key}
                                                required={item.value.required}
                                                type={item.value.type}
                                            />
                                        </div>
                                    );
                                })}
                            <div className="modalButtonContainer">
                                <Buttons
                                    className="buttonModal_white"
                                    text={"Отмена"}
                                    onClick={() => {
                                        closeModal();
                                    }}
                                />
                                <Buttons
                                    className="buttonModal"
                                    text={"Добавить"}
                                    onClick={() => {
                                        addLifeSituationServices(updateData ? "update" : "");
                                    }}
                                />
                            </div>
                        </div>
                    }
                    onClose={() => {
                        closeModal();
                    }}
                />
            )}
            {addFileModal && (
                <Modal
                    content={
                        <div className="modalContainerGrid">
                            <h1>Данные о реинжиниринге</h1>
                            <div className="modalServiceFile">
                                <div className="containerServiceFile">
                                    <div className="containerCheckFile">
                                        {
                                            //@ts-ignore
                                            services?.map(
                                                (service: any) =>
                                                    service.id === selectedCard &&
                                                    service.reengineering_files.length !== 0 && (
                                                        <img src={icons.check}></img>
                                                    )
                                            )
                                        }
                                        <p>Все процессы</p>
                                    </div>
                                    <Toast ref={toast}></Toast>
                                    {
                                        //@ts-ignore
                                        services?.map(
                                            (service: any) =>
                                                service.id === selectedCard &&
                                                (service.reengineering_files.length === 0 ? (
                                                    <FileUpload
                                                        mode="basic"
                                                        name="files"
                                                        url={`${apiConfig.baseUrl}services/${selectedCard}/upload-files/`}
                                                        accept="*/*"
                                                        // maxFileSize={10000000}
                                                        chooseLabel="Загрузить"
                                                        onUpload={uploadFilesAllServices}
                                                        auto
                                                    />
                                                ) : (
                                                    <a
                                                        href={service.reengineering_files.map(
                                                            (item: any) =>
                                                                `${apiConfig.baseUrlMedia.slice(0, -7)}${
                                                                    item.file
                                                                }`
                                                        )}
                                                        download={service.reengineering_files.map(
                                                            (item: any) =>
                                                                `${apiConfig.baseUrlMedia.slice(0, -7)}${
                                                                    item.file
                                                                }`
                                                        )}
                                                        target="_blank"
                                                    >
                                                        Скачать
                                                    </a>
                                                ))
                                        )
                                    }
                                </div>
                                {
                                    //@ts-ignore
                                    services?.map(
                                        //@ts-ignore
                                        (service, serviceIndex) =>
                                            service.id === selectedCard &&
                                            service.processes.map((item: any) => {
                                                return (
                                                    <div className="containerServiceFile">
                                                        <div className="containerCheckFile">
                                                            {item.reengineering_files.length !== 0 && (
                                                                <img src={icons.check}></img>
                                                            )}
                                                            <p key={`${serviceIndex}-${item.id}`}>
                                                                {item.identifier}
                                                            </p>
                                                        </div>

                                                        {service.reengineering_files.length === 0 ? (
                                                            item.reengineering_files.length === 0 ? (
                                                                <FileUpload
                                                                    mode="basic"
                                                                    name="files"
                                                                    url={`${apiConfig.baseUrl}processes/${item.id}/upload-files/`}
                                                                    accept="*/*"
                                                                    maxFileSize={10000000}
                                                                    onUpload={uploadFilesAllServices}
                                                                    chooseLabel="Загрузить"
                                                                    auto
                                                                    uploadLabel="Отправить"
                                                                />
                                                            ) : (
                                                                <a
                                                                    href={item.reengineering_files.map(
                                                                        (item: any) =>
                                                                            `${apiConfig.baseUrlMedia.slice(0, -7)}${
                                                                                item.file
                                                                            }`
                                                                    )}
                                                                    download={item.reengineering_files.map(
                                                                        (item: any) =>
                                                                            `${apiConfig.baseUrlMedia.slice(0, -7)}${
                                                                                item.file
                                                                            }`
                                                                    )}
                                                                    target="_blank"
                                                                >
                                                                    Скачать
                                                                </a>
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                );
                                            })
                                    )
                                }
                            </div>
                        </div>
                    }
                    onClose={() => setAddFileModal(false)}
                ></Modal>
            )}
            <div
                className={`lifeSituationServiceContainer ${
                    lifeSituationActive && "flexGrid"
                }`}
            >
                {/*@ts-ignore*/}
                {services?.map((service) => (
                    <div className="containerServiceProcesses">
                        <div key={service?.id} className="cardLifeSituationServices">
                            <div
                                className="cardHeader"
                                onClick={(e) => {
                                    service?.id && toggleCardSelection(service?.id);
                                }}
                            >
                                <div className="cardHeaderTitleType">
                                    <h3 className="cardHeaderSubtitle">
                                        {service?.service_type || ""}
                                    </h3>
                                    {
                                        <div key={service.id}>
                                            {service.reengineering_files.length !== 0 ? (
                                                <div className="indicator"></div>
                                            ) : service.processes.every(
                                                (item: any) => item.reengineering_files.length !== 0
                                            ) ? (
                                                <div className="indicator green"></div>
                                            ) : service.processes.some(
                                                (item: any) => item.reengineering_files.length !== 0
                                            ) ? (
                                                <div className="indicator yellow"></div>
                                            ) : null}
                                        </div>
                                    }
                                </div>
                                <h1>{service?.name}</h1>
                            </div>
                            <div className="cardFooter">
                                <div className="footerRight">
                                    <p className="cardFooterNumber">
                                        {service?.regulating_act || ""}
                                    </p>
                                    <div className="footerFlex">
                                        <p className="cardFooterNumber">
                                            {service?.identifier || ""}
                                        </p>
                                        <div className="containerButtonCard">
                                            {!readonly && (
                                                <Buttons
                                                    className="whiteIco"
                                                    ico={icons.edit}
                                                    text={""}
                                                    toolTip={"Редактировать"}
                                                    onClick={() => {
                                                        addLifeSituation("update", service?.id);
                                                    }}
                                                />
                                            )}
                                            <Buttons
                                                className="whiteIco buttoPosition"
                                                ico={icons.folder}
                                                text={""}
                                                toolTip={"Файлы"}
                                                onClick={() => {
                                                    addFileLifeSitauation();
                                                    service?.id && setSelectedCard(service?.id);
                                                }}
                                            />
                                            {user.is_admin &&
                                                (service.reengineering_files.length !== 0 ? (
                                                    <Checkbox
                                                        id={service.id}
                                                        label={"Лучшая практика"}
                                                        //@ts-ignore
                                                        checked={
                                                            service.is_best_practice ||
                                                            //@ts-ignore
                                                            !!bestPractices[service.id]
                                                        }
                                                        onChange={() => handleCheckboxChange(service.id)}
                                                        disabled={readonly}
                                                    />
                                                ) : service.processes.every(
                                                    (item: any) => item.reengineering_files.length !== 0
                                                ) ? (
                                                    <Checkbox
                                                        id={service.id}
                                                        label={"Лучшая практика"}
                                                        //@ts-ignore
                                                        checked={
                                                            service.is_best_practice ||
                                                            //@ts-ignore
                                                            !!bestPractices[service.id]
                                                        }
                                                        onChange={() => handleCheckboxChange(service.id)}
                                                    />
                                                ) : null)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {selectedCards.includes(service.id ? service.id : "") && (
                            <LifeSituationProcessItem
                                processes={service.processes}
                                servicesId={service?.id}
                                // processesOption={
                                //     servicesOption?.services?.child?.children?.processes
                                // }
                                readonly={readonly}
                            />
                        )}
                    </div>
                ))}
                {(!lifeSituationActive && !readonly) && (
                    <Buttons
                        ico={icons.plusCircle}
                        text={"Добавить услугу/функцию/сервис"}
                        className="whiteButtonAdd"
                        onClick={() => {
                            addLifeSituation();
                        }}
                    />
                )}
            </div>
        </>
    );
};

export default LifeSituationServiceItem;